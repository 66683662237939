import React from "react";

type Props = {
  size: number;
};

const CircleLoader = (props: Props) => {
  return (
    <div>
      <span className="loader-sp"></span>
      <style>{`
          .loader-sp {
    width: ${props?.size}px;
    height: ${props?.size}px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    }
          `}</style>
    </div>
  );
};

export default CircleLoader;

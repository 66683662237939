import React, { useState } from "react";
import styles from "./Otp.module.scss";
import OtpInput from "react-otp-input";
import Otptime from "./Otptime";
import { useOtpVerifyMutation } from "@/redux/Authentication/api";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/Root/store";
import { useDispatch } from "react-redux";
import {
  updateCredentials,
  updateUser,
} from "@/redux/Authentication/AuthReducer";
import Router from "next/router";
import toast from "react-hot-toast";
import CircleLoader from "@/components/Common/CircleLoader";

interface Props {}

export default function Otp({}: Props) {
  const [otp, setotp] = useState("");
  const [otpVerify] = useOtpVerifyMutation();
  const dispatch = useDispatch();

  const redux_data = useSelector((state: RootState) => state.auth.user);
  const [loader, setLoader] = useState(false);

  const handleChange = (otp: any) => {
    setotp(otp);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);

    otpVerify({ mobile: redux_data.mobile, otp: otp, device: "app_web" }).then(
      (res: any) => {
        console.log(res);
        if (res?.data?.status == "ok") {
          const data: any = res.data;
          // dispatch(updateUser(data?.payload?.user));
          dispatch(updateCredentials(data?.payload?.token));
          setLoader(false);
          Router.push("/");
        } else if (res?.error?.status == 400) {
          toast.error(res?.error?.data?.message);
        }
      }
    );
  };

  return (
    <div>
      <h2 className="text-[36px] text-[#363352] font-[700] capitalize">
        enter the otp to login{" "}
      </h2>

      <p className="mt-5 text-[14px]">
        Please send 6 digit otp, send to{" "}
        <span className=" text-[16px] text-[#E81A6E] font-semibold">
          {redux_data.mobile}
        </span>
      </p>
      <h4 className="mt-3"></h4>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="otpContrainer my-10">
          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={6}
            separator={<span>-</span>}
            isInputNum={true}
          />
        </div>

        <p className=" text-[15px]">Didn&apos;t receive OTP on mobile ?</p>

        <p className=" text-[14px] my-4 text-[#E81A6E]"> Resend Code </p>

        <button
          type="submit"
          className={`block ${
            otp.length == 6 ? "bg-[#E81A6E]" : "bg-[#df729e]"
          } ${styles.buttonsubmit}  text-white text-center px-14 py-2`}
          disabled={otp.length == 6 ? false : true}
        >
          {" "}
          {loader ? <CircleLoader size={24} /> : "Verify"}
        </button>
      </form>
    </div>
  );
}

import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { apiKit } from "base_config/apiKit";
import {
  LoginWithEmail,
  SignUpResponse,
  SignUp,
  LoginWithOtp,
  OtpVerifyResponse,
  SendOtp,
} from "./models";

export const authApi = apiKit.injectEndpoints({
  endpoints: (build) => ({
    loginWithEmail: build.mutation<void, LoginWithEmail>({
      query(data) {
        return {
          url: `auth/login/`,
          method: "POST",
          body: data,
        };
      },
    }),
    signUp: build.mutation<SignUpResponse, SignUp>({
      query(data) {
        return {
          url: `auth/registration/`,
          method: "POST",
          body: { ...data },
        };
      },
    }),
    sendOtp: build.mutation<OtpVerifyResponse, SendOtp>({
      query(data) {
        return {
          url: `auth/otp/send/`,
          method: "POST",
          body: { ...data },
        };
      },
    }),
    otpVerify: build.mutation<OtpVerifyResponse, LoginWithOtp>({
      query(data) {
        return {
          url: `auth/otp/verification/`,
          method: "POST",
          body: { ...data },
        };
      },
    }),
  }),
});

export const {
  useLoginWithEmailMutation,
  useSignUpMutation,
  useOtpVerifyMutation,
  useSendOtpMutation,
} = authApi;
